import { useField } from 'remix-validated-form';
import type { ChangeEvent } from 'react';
import { BaseInput } from './BaseInput';

type InputProps = {
  name: string;
  label: string | null;
  type: string;
  className?: string;
  autocomplete?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  tooltip?: string;
  formId: string;
  placeholder?: string;
};

export function Input(props: InputProps) {
  const { formId, ...restProps } = props;

  const { error, getInputProps } = useField(props.name, {
    formId: formId,
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onSubmit',
    },
  });

  return (
    <BaseInput {...restProps} error={error} getInputProps={getInputProps} />
  );
}
